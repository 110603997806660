import React from 'react';
import { formatPrice } from './NumberFormatting';

const EmptyCart = ({ cart }) => {
    const onClick = (event) => {
        event.preventDefault();
        cart.empty();
    };
    return (
        <a href='about:blank' onClick={onClick}>Clear</a>
    )
};

export const TotalCartItem = ({ cart }) => {
    // const shippingCost = cart.totalCost > 50 ? 0 : 3;
    return (
        <div className="columns is-mobile iscentered">
            <div className="column is-one-fifth"><b>Total</b></div>
            <div className="column is-two-fifth"></div>
            <div className="column is-one-fifth">
                {cart.totalItems} Item{(cart.totalItems > 1) ? 's' : ''}
                {(cart.totalItems > 0) ? (<span>  (<EmptyCart cart={cart}/>)</span>) : ''}
            </div>
            <div className="column is-one-fifth">
                <b>{formatPrice(cart.totalCost)}</b>
            </div>
        </div>
     );
}
 
// export default CheckoutItem;