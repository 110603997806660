import React from 'react';
import { formatPrice } from './NumberFormatting';

export const ShippingCartItem = ({ cart }) => {
    // const shippingCost = cart.totalCost > 50 ? 0 : 3;
    return (
        <div className="columns is-mobile iscentered">
            <div className="column is-one-fifth">Shipping</div>
            <div className="column is-two-fifth">Royal Mail</div>
            <div className="column is-one-fifth">{(cart.shippingCost === 0) ? 'Free Shipping!' : ''}</div>
            <div className="column is-one-fifth">
                <b>{formatPrice(cart.shippingCost)}</b>
            </div>
        </div>
     );
}
 
// export default CheckoutItem;