import React from 'react';
import { CountryList } from './CountriesData';

export const CountryDropDown = ({ id, label, name, onChange }) => (
    <div className="field">
        <label htmlFor={id} className="label">{label}</label>
        <div className="control">
            <div className="select is-small">
                <select tabIndex="-1" id={id} name={name} defaultValue='GB' onChange={onChange} >
                    { CountryList.map((country) => {
                        return (
                            <option key={country.id} value={country.id}>{country.country}</option>
                        )
                    }) }
                </select>
            </div>
        </div>
    </div>
);

export const CountryDropDownError = ({ message }) => {
    if (message === '') return null;
    else return (<div className="country_error">{message}</div>)
};

export default CountryDropDown;