import React from 'react';
import { validate as validateEmail } from 'email-validator';

import { InputFieldText } from './InputFieldText';
import { CountryDropDown, CountryDropDownError } from './CountryDropDown';

const onlyUkOrdersMessage = 'Sorry, we are currently not accepting orders online for outside of the UK. Please contact us.';

export class CheckoutFormContact extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            validEmail: false,
            validCountry: true,
            validPhone: false,
            validAddress: false,
            validPostcode: false,
            countryError: ''
        };
        this.eles = {};
        this.onComplete = props.onComplete;
    }

    componentDidMount() {
        const fieldsToMount = ['email', 'phone', 'name', 'address1', 'address2', 'city', 'state', 'zip', 'country'];
        fieldsToMount.map((field) => {
            this.eles[field] = document.getElementById(`input_${field}`);
            return undefined;
        });
        // this.eles['email'] = document.getElementById('input_email');
        // this.eles['phone'] = document.getElementById('input_phone');
        // this.eles['country'] = document.getElementById('input_country');
        // this.eles['address1'] = document.getElementById('input_address1');

        
    }

    handleEmailChange(event) {
        // event.prevenDefault();
        if (validateEmail(this.eles.email.value) === true) {
            if (this.state.validEmail === false) {
                this.setState((prevState) => {
                    return {...prevState, validEmail: true};
                });
            }
        } else if (this.state.validEmail === true) {
            this.setState((prevState) => {
                return {...prevState, validEmail: false};
            });
        }
    }

    handleAddressOneChange() {
        if (this.eles.address1.value === '') {
            if (this.state.validAddress === true) {
                this.setState((prevState) => {
                    return {...prevState, validAddress: false };
                });
            }
        } else {
            if (this.state.validAddress === false) {
                this.setState((prevState) => {
                    return {...prevState, validAddress: true };
                });
            }
        }
    }

    handleAddressPostcodeChange() {
        if (this.eles.zip.value === '') {
            if (this.state.validPostcode === true) {
                this.setState((prevState) => {
                    return {...prevState, validPostcode: false };
                });
            }
        } else {
            if (this.state.validPostcode === false) {
                this.setState((prevState) => {
                    return {...prevState, validPostcode: true };
                });
            }
        }
    }

    handleOrderDetailsSubmit(event) {
        event.preventDefault();
        const customer = {
            name: this.eles.name.value,
            address_1: this.eles.address1.value,
            address_2: this.eles.address2.value,
            address_city: this.eles.city.value,
            address_state: this.eles.state.value,
            address_zip: this.eles.zip.value,
            address_country: this.eles.country.value,
            email: this.eles.email.value,
            phone: this.eles.phone.value,
        };

        this.onComplete(customer);
    }

    handleAddressCountryChange() {
        const countryCode = this.eles.country.options[this.eles.country.selectedIndex].value;
        if (countryCode !== 'GB') {
            if (this.state.validCountry === true) {
                this.setState((prevState) => {
                    return {...prevState, validCountry: false, countryError: onlyUkOrdersMessage };
                });
            }
        } else {
            if (this.state.validCountry === false) {
                this.setState((prevState) => {
                    return {...prevState, validCountry: true, countryError: '' };
                });
            }
        }
    }

    handlePhoneChange() {
        if (this.eles.phone.value === '') {
            if (this.state.validPhone === true) {
                this.setState((prevState) => {
                    return {...prevState, validPhone: false };
                });
            }
        } else {
            if (this.state.validPhone === false) {
                this.setState((prevState) => {
                    return {...prevState, validPhone: true };
                });
            }
        }
    }

    render() {

        return (
            <form id="order_details" onSubmit={this.handleOrderDetailsSubmit.bind(this)}>
                <div className="columns">
                    <div className="column is-third">
                        <InputFieldText id="input_name" label="Name" name="name" width="12em" />
                        <InputFieldText id="input_address1" label={(this.state.validAddress) ? 'Address 1' : 'Address 1 ❗'} name="address1"  width="16em" onChange={this.handleAddressOneChange.bind(this)} />
                        <InputFieldText id="input_address2" label="Address 2" name="address2" width="16em" />

                        <CountryDropDown id="input_country" label={(this.state.validCountry) ? 'Country' : 'Country ❗'} name="country" selected="GB" onChange={this.handleAddressCountryChange.bind(this)}/>
                        <CountryDropDownError message={this.state.countryError} />
                    </div>
                    <div className="column is-third">
                        <InputFieldText id="input_city" label="City" name="city" width="12em" />
                        <InputFieldText id="input_state" label="County" name="state" width="12em" />
                        
                        <InputFieldText id="input_zip" label={(this.state.validPostcode) ? 'Postcode' : 'Postcode ❗'} name="zip" width="8em" onChange={this.handleAddressPostcodeChange.bind(this)} />
                    </div>
                    <div className="column is-third">
                        <InputFieldText id="input_email" label={(this.state.validEmail) ? 'email' : 'email ❗'} name="email" onChange={this.handleEmailChange.bind(this)}/>
                        <InputFieldText id="input_phone" label={(this.state.validPhone) ? 'Phone' : 'Phone ❗'} name="phone" onChange={this.handlePhoneChange.bind(this)} />
                        <button className="button"
                            onClick={this.handleOrderDetailsSubmit.bind(this)}
                            aria-label={`Submit`}
                            disabled={this.state.validCountry === false || this.state.validEmail === false || this.state.validPhone === false}
                        >
                            Submit
                        </button>
                    </div>
                </div>
            </form>
        );
    }
}