import React from 'react';

export const InputFieldText = ({ floatLeft = false, id, label, name, onChange = () => {}, placeholder = '', value = '', width = '100%' }) => (
    <div className="field" style={ (floatLeft === true) ? { float: 'left' } : {} }>
        <label htmlFor={id} className="label">{label}</label>
        <div className="control">
            <input id={id} name={name} style={ { width } } onChange={() => {onChange();}} className="input is-small" type="text" placeholder={placeholder} defaultValue={value}></input>
        </div>
    </div>
);


export default InputFieldText;