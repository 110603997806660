import React, { Component } from 'react';
import { CheckoutItem } from './CheckoutItem';
import { ShippingCartItem } from './ShippingCartItem';
import { TotalCartItem } from './TotalCartItem';
import { CartContextConsumer } from '../contexts/CartContext';
import { ProductsContextConsumer } from '../contexts/ProductsContext';

export class CheckoutItems extends Component {

    render() {

        return (
            <ProductsContextConsumer>
                {productsContext => (
                    <CartContextConsumer>
                        {cart => {
                            /* if (cart.totalItems === 0) {
                                let rtv = [];
                                rtv.push((<Link to="/products">Empty, check out our products.</Link>));
                                return rtv;
                            } */
                            let rtv = cart.items.map(product => (<CheckoutItem
                                key={`${product.sku}-${product.variant}`}
                                product={product}
                                products={productsContext}
                                increaseItem={cart.increaseItem}
                                decreaseItem={cart.decreaseItem}
                                removeItem={cart.removeItem}
                            />));
                            if (cart.totalItems > 0) {
                                rtv.push((<ShippingCartItem key="shipping" cart={cart} />));
                                rtv.push((<TotalCartItem key="total" cart={cart} />));
                            } else {
                                rtv.push((<div className="has-text-centered"><span>Empty cart, check out our beautiful products <a href="/products"><span>here.</span></a></span></div>))
                            }
                            return rtv;
                        }}
                    </CartContextConsumer>
                )}
            </ProductsContextConsumer>
        )
    }
}

export default CheckoutItems;