import React, { Component } from 'react';
import { CartContextConsumer, CartContext } from '../contexts/CartContext'
import axios from 'axios';
import { CheckoutFormContact } from './CheckoutFormContact';
import { formatPrice } from './NumberFormatting';

const validOrigins = ['http://127.0.0.1:8888', 'https://kailash-copper.netlify.app'];

const PaypalButton = ({ targetUrl, callback }) => {
    const onClick = (event) => {
        event.preventDefault();
        window.open(targetUrl, 'WIPaypal', 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=1060, height=700');
        window.addEventListener('message', (event) => {
            if (validOrigins.indexOf(event.origin) > -1) {
                const data = JSON.parse(event.data);
                console.dir(data);
                // if (event.data && ('paid' in event.data) && event.data.paid === true) {
                    callback('postMessage');
                // }
            }
        });
        callback('open');
        return false;
    }
    return (
    <a href={targetUrl} title="Check out with PayPal" onClick={onClick}>
        <img src="https://www.paypalobjects.com/webstatic/en_US/i/buttons/checkout-logo-small.png" alt="Check out with PayPal" />
    </a>
)};

export class CheckoutForm extends Component {
    static contextType = CartContext

    constructor(props) {
        super(props);
        this.defaultState = {
            progress: 'cart-list',
            orderDetails: {},
            orderId: 'Unknown',
            errorMessage: '',
            approveUrl: '',
            paypalId: ''
        };
        this.state = this.defaultState;
        this.lookingUpOrder = false;
        this.updateParentProgress = props.setProgress;
    }

    componentDidMount() {
        const existingState = (typeof localStorage !== 'undefined' && localStorage.getItem('order') !== null) ? JSON.parse(localStorage.getItem('order')) : this.defaultState;
        this.setState(existingState);

    }

    save() {
        if (typeof localStorage !== 'undefined') {
            setTimeout(() => {
                localStorage.setItem('order', JSON.stringify(this.state));
            }, 5);
        }
    }

    updateProgress(newProgress) {
        this.setState(prevState => {
            const newState = {...prevState, progress: newProgress };
            return newState;
        })
        
        this.save();
        
        
    }

    updateOrderId(orderId) {
        this.setState(prevState => {
            return {...prevState, orderId };
        });
        this.save();
    }

    updateOrderDetails(orderDetails) {
        this.setState(prevState => {
            return {...prevState, orderDetails };
        });
        this.save();
    }

    updateApproveUrl(approveUrl) {
        this.setState(prevState => {
            return {...prevState, approveUrl };
        });
        this.save();
    }

    checkout() {
        this.updateProgress('order-details-input');
    }

    contactFormCompleted(customer) {
        let shippingCost = 0;
        if (customer.address_country === 'GB') {
            if (this.state.totalCost > 50) {
                shippingCost = 0.00;
            } else {
                shippingCost = 3.00;
            }
        } else {
            return alert('Not currently shipping outside of UK, please contact us to place an order.');
        }

        const order = {
            customer,
            items: this.context.items,
            payment_ref: '',
            payment_paid: false,
            shipping_order_created: false,
            shipping_cost: shippingCost
        };

        this.updateOrderDetails(order);
        this.retrySubmit(order);
        this.updateProgress('order-details-submitting');
    }

    retrySubmit(order) {
        const instance = axios.create();
        instance.defaults.headers['Content-Type'] = 'application/json';
        instance.defaults.headers['Cache-Control'] = 'no-cache';
        instance.defaults.withCredentials = false;
        instance.post(`/.netlify/functions/submit-order`, JSON.stringify(order)).then((resp) => {
            this.setState((prevState) => {
                return {...prevState, paypalId: resp.data.paypalId, orderId: resp.data.id, approveUrl: resp.data.approveUrl, progress: 'order-details-submitted'};
            });
            this.save();
        }).catch((err) => {
            alert(err);
        });
    }

    paypalCallback(state) {
        console.log('paypalCallback ' + state);
        if (this.lookingUpOrder === false) {
            this.lookingUpOrder = true;
            const instance = axios.create();
            instance.defaults.headers['Content-Type'] = 'application/json';
            instance.defaults.headers['Cache-Control'] = 'no-cache';
            instance.defaults.withCredentials = false;
            console.log(`id ${this.state.orderId}`);
            const payload = {
                "id": this.state.orderId
            };
            // const that = this;
            setTimeout(() => {
                instance.post(`/.netlify/functions/get-order`, JSON.stringify(payload)).then((function(resp) {
                    if (resp.data.payment_paid === true) {
                        this.updateProgress('order-paid');
                        this.lookingUpOrder = false;
                    } else {
                        // console.dir(this);
                        // console.dir(that);
                        if (this.state.paypalId !== '' || resp.data.payment_ref !== '') {
                            const payloadProcess = {
                                ref:
                                  (this.state.paypalId !== '') ?
                                    this.state.paypalId
                                  : (resp.data.payment_ref !== '') ?
                                    resp.data.payment_ref
                                  : 'unknown'
                            };
                            instance.post(`/.netlify/functions/process-paypal-payment`, JSON.stringify(payloadProcess)).then((resp) => {
                                if (resp.data.paid === true) {
                                    this.updateProgress('order-paid');
                                } else {
                                    console.error('NOT PAID', resp.data);
                                }
                                this.lookingUpOrder = false;
                            }).catch((err) => {
                                this.lookingUpOrder = false;
                                console.error(err);
                            });
                        }
                    }
                }).bind(this)).catch((err) => {
                    this.lookingUpOrder = false;
                    console.error(err);
                });
            }, 500);
        }
        return false;
    }

    newOrder() {
        this.setState(this.defaultState);
        this.save();
    }
    

    render() {
        return (
            <CartContextConsumer>
                {cart => {
                    switch(this.state.progress) {
                        case 'cart-list':
                            this.updateParentProgress(5);
                            // const shippingCost = (cart.totalCost > 50) ? 0 : 3;
                            // console.log(`shippingCost: ${shippingCost}`);
                            // console.dir(cart);

                            return (
                                <div className="columns is-mobile is-centered">
                                    <div className="column is-three-fifths has-text-centered">
                                    <button className="button"
                                        onClick={() => this.checkout()}
                                        aria-label={`Checkout`}
                                        disabled={cart.totalItems === 0}
                                        >
                                        Checkout
                                        </button>
                                    </div>
                                    <div className="column is-one-fifth">{cart.totalItems} Item{(cart.totalItems > 1) ? 's' : ''}</div>
                                    <div className="column is-one-fifth">Total: {formatPrice(cart.totalCost)}</div>
                                </div>
                            );
                        case 'order-details-input':
                            this.updateParentProgress(10);
                            return (
                                <div>
                                    <CheckoutFormContact onComplete={this.contactFormCompleted.bind(this)}/>
                                </div>
                            );
                        case 'order-details-submitting':
                            this.updateParentProgress(25);
                            return (
                                <div>
                                <div className="columns is-mobile iscentered">
                                    <div className="column has-text-centered">
                                        Please wait, submitting your order...<br />
                                        <button className="button" onClick={this.retrySubmit.bind(this, this.state.orderDetails)}>Retry</button>
                                    </div>
                                </div>
                                </div>
                            )
                        case 'order-details-submitted':
                            this.updateParentProgress(50);
                            setTimeout(() => {
                                this.paypalCallback.bind(this)('pageload');
                            }, 1);
                            return (
                                <div>
                                <div className="columns is-mobile iscentered">
                                    <div className="column has-text-centered">
                                    Order ID {this.state.orderId} submitted.<br/> 
                                    Please click below to pay via Paypal.
                                    </div>
                                </div>
                                <div className="columns is-mobile is-centered">
                                    <div className="column has-text-centered">
                                        <PaypalButton targetUrl={this.state.approveUrl} callback={this.paypalCallback.bind(this)} />
                                    </div>
                                </div>
                                </div>
                            )
                        case 'order-paid':
                            this.updateParentProgress(80);
                            return (
                                <div>
                                <div className="columns is-mobile iscentered">
                                    <div className="column has-text-centered">
                                    Order ID {this.state.orderId} Paid.<br/>
                                    We'll email you when it's shipped.
                                    </div>
                                </div>
                                <div className="columns is-mobile iscentered">
                                    <div className="column has-text-centered">
                                        <button className="button" onClick={this.newOrder.bind(this)}>New Order</button>
                                    </div>
                                </div>
                                </div>
                            );
                        default:
                            return (<h2>Default</h2>);
                    }
                }}
            </CartContextConsumer>
        )
    }
}

export default CheckoutForm;