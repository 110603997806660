import React from 'react'
import { navigate } from 'gatsby-link'
import Layout from '../../components/Layout';
import { Header } from '../../components/Header';

import { CheckoutItems } from '../../components/CheckoutItems';
import { CheckoutForm } from '../../components/CheckoutForm';

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export default class Checkout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isValidated: false,
      progress: 0
    }
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error))
  }

  setProgress(progress) {
    if (progress !== this.state.progress) {
      setTimeout(() => {
        this.setState(prevState => {
          const newState = {...prevState, progress: progress };
          return newState;
        });
      }, 5);
      /* this.setState(prevState => {
        const newState = {...prevState, progress: progress };
        return newState;
      }); */
    }
  }

  render() {
    return (
      <Layout>
        <Header title="Checkout" subheading="" image="/img/kailash-three-bottles.webp" />
        <div className="container">
          <div className="columns">
            <div className="column is-10 is-offset-1">
            <div className="section">
            <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
              Checkout
            </h2>
              <div className="section is-centered">
                <CheckoutItems />
              </div>
              <div className="section">
                <progress className="progress" value={this.state.progress} max="100">{this.state.progress}5%</progress>
                <CheckoutForm setProgress={this.setProgress.bind(this)} />
              </div>
            </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}
